@use '@angular/material' as mat;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

mat.$green-palette: (
  50: #80BAD9,
  100: #60A9CF,
  200: #4098C6,
  300: #2086BC,
  400: #0075B3,
  500: #1076AD,
  600: #2078A6,
  700: #3079A0,
  800: #407A99,
  900: #507C93,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
  )
);

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary: mat.define-palette(mat.$green-palette);
$primary-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$primary-theme: mat.define-light-theme($primary, $primary-accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($primary-theme);

.mat-drawer-container {
  background-color: #fff;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
