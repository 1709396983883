// regular style toast
@import "~ngx-toastr/toastr";
@import "~ag-grid-community/dist/styles/ag-grid.scss";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";

$primary-color: #0075b3;
$secondary-color: #f29200;
$tertiary-color: #13a538;

body,
h1,
span,
p,
div,
button,
.mat-card,
p-table,
.p-inputtext {
  font-family: "Montserrat", sans-serif;
}

body {
  overflow: hidden;
}

.apexcharts-legend-series {
  padding: 10px 5px 10px 5px;
}

.mat-tooltip {
  font-size: 14px !important;
}

.mat-select-panel.virtual-scroll {
  max-height: 100% !important;
  overflow: inherit !important;
}

.mat-select-panel .cdk-virtual-scroll-viewport {
  max-height: 240px !important;
}

.mat-select-panel .cdk-virtual-scroll-content-wrapper {
  position: inherit !important;
  top: inherit !important;
  left: 0;
}

.cdk-global-scrollblock {
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}

mat-icon.indicator {
  color: #399f14 !important;

  &.indicator-yellow {
    color: #dcd255 !important;
  }

  &.indicator-red {
    color: red !important;
  }

  &.indicator-error {
    color: grey !important;
  }
}

.reports {
  background-color: #fff;
}

// Customizando prime NG
.pi {
  font-family: "primeicons";
}

.p-button {
  background-color: $primary-color !important;

  &:active {
    background-color: $primary-color !important;
    opacity: 0.81;
  }
}

.custom-header-blue {
  background-color: rgb(0, 117, 179);
  color: #fff;
  .ag-icon {
    color: #fff;
  }
}
.custom-header-green {
  background-color: rgb(19, 97, 81);
  color: #fff;
  .ag-icon {
    color: #fff;
  }
}
.custom-header-red {
  background-color: rgb(202, 69, 52);
  color: #fff;
  .ag-icon {
    color: #fff;
  }
}
.custom-header-black {
  background-color: #000;
  color: #fff;
  .ag-icon {
    color: #fff;
  }
}
.custom-header-white {
  background-color: #fff;
  color: #000;
  .ag-icon {
    color: #000;
  }
}

.ag-layout-normal {
  border: none !important;
}

.ag-cell {
  span {
    font-family: "Roboto" !important;
  }
}

.p-sidebar-mask {
  z-index: 8 !important;
}

#beamerSelector {
  display: none !important;
}
